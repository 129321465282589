export const importRouteJs = (controller, action) =>
  Promise.all([import(`./controllers/${controller}.ts`), import(`./controllers/${controller}/${action}.ts`)]);

function getBaseUrl() {
  const base = document.querySelector("base");
  if (base) {
    // Remove trailing slash
    return base.href.replace(/\/$/, '');
  }

  return window.location.origin;
}

function getPathName() {
  const baseUrl = getBaseUrl();
  const urlWithPath = window.location.origin + window.location.pathname;

  // remove the retrieved baseUrl from the urlWithPath
  return urlWithPath.replace(baseUrl, '');
}

export const getRoute = () => {
  let [_, controller, action] = getPathName().split('/');

  controller = controller || 'index';
  action = action || 'index';

  return {controller, action};
};

export const {controller, action} = getRoute();
