export const setupLogoutHandler = () => {
  const header = document.querySelector('cbar-header');
  const logoutButton = document.querySelector('a.logout_button');
  if(!header && !logoutButton){
    return;
  }

  if(logoutButton) {
    logoutButton.setAttribute('href', '');
    logoutButton.addEventListener('click', handleLogout);
  }

  if(header) {
    header.addEventListener('logout', handleLogout);
  }
};

const handleLogout = (e) => {
  e.preventDefault();

  const header = document.querySelector('cbar-header');
  const logoutDialog = document.querySelector('cbar-app-logout');
  const logoutButton = document.querySelector('a.logout_button');

  if((!header && !logoutButton) || !logoutDialog){
    return;
  }

  // if not set this will default to the property default (authentickated)
  // @ts-ignore
  logoutDialog.toggle();

  logoutDialog.addEventListener('auth-logout', () => {
    window.location.href = "/auth/logout";
  });

  logoutDialog.addEventListener('app-logout', async () => {
    window.location.href = '/auth/logout?app_logout=1';
  });
};