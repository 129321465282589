import * as Papa from 'papaparse';

const fileInput = document.querySelector('#import_file') as HTMLInputElement;
const div = document.querySelector("#data")!;

fileInput.addEventListener('change', (e) => {
    // @ts-ignore
    Papa.parse(fileInput!.files[0], {
        complete: function(results) {
            if (results.data.length > 0) {
                document.querySelector("#preview")!.setAttribute('style', 'display:block');
                div.innerHTML = '';
                const tbl = document.createElement('table');
                tbl.setAttribute('class', 'table');
                // @ts-ignore
                generateTable(results.data, tbl);
                div.append(tbl);
            } else {
                document.querySelector("#preview")!.setAttribute('style', 'display:block');
                let message = document.createTextNode("Data not found.");
                div.innerHTML = '';
                div.append(message);
            }
        }
    });

    function generateTable(data, tbl) {
        let i = 1;
        data.map((data)=> {
            if (i === 1) {
                let tbody = tbl.createTHead();
                let row = tbody.insertRow();
                for (let key of data) {
                    let tdata = document.createElement('th');
                    let text = document.createTextNode(key);
                    tdata.appendChild(text);
                    row.setAttribute('class', 'warning')
                    row.appendChild(tdata);
                }
            } else {
                let row = tbl.insertRow();
                for (let key of data) {
                    let tdata = document.createElement('td');
                    let text = document.createTextNode(key);
                    tdata.appendChild(text);
                    row.appendChild(tdata);
                }
            }
            i++;
        });
    }
});

