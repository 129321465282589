import {createDateRangePickers, formatVaadinDates} from "../../common/date";
import {documentReady} from "../../document-ready";

documentReady().then(() => {
    // const specificPickers = document.querySelectorAll('vaadin-date-picker');
    const invoiceDateFrom = document.getElementById('invoice_date_from');
    const invoiceDateTo = document.getElementById('invoice_date_to');

    formatVaadinDates([invoiceDateFrom,invoiceDateTo], "YYYY-MM-DD");
    createDateRangePickers(invoiceDateFrom, invoiceDateTo);
});