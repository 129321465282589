const ironForm = document.querySelector("#step_form") as HTMLFormElement;
const stepForm = ironForm.querySelector("form")!;

const stepDirection = document.querySelector('#step_direction') as HTMLInputElement | null;

const btnBack = document.querySelectorAll('.step_backward_btn')!;
const btnForward = document.querySelectorAll('.step_forward_btn')!;

const btnSubmit = document.querySelectorAll('.step_final_btn')!;
const btnCancel = document.querySelectorAll('.step_cancel')!;

const orgIdInput = document.querySelector('#org_id') as HTMLInputElement;
const orgId = orgIdInput.value;
const actionInput = document.querySelector('#action') as HTMLInputElement;
const action = actionInput.value;
const recordTypeInput = document.querySelector('#record_type') as HTMLInputElement;
const recordType = recordTypeInput.value;

declare const swal;

// Adds a listener to an array of elements
const listen = (elements, event, fn) =>
    elements.forEach(e => e.addEventListener(event, fn));

const stepBack = () => {
    stepDirection!.value = "backward";

    // Use the regular <form> to avoid validating elements
    stepForm.submit();
}

const stepForward = () => {
    stepDirection!.value = "forward";

    ironForm.submit();
}

const proceed = () => {
    stepDirection!.value = "forward";

    // Use the regular <form> to avoid validating elements
    stepForm.submit();
}

listen(btnBack, 'click', stepBack);

listen(btnForward, 'click', stepForward);

listen(btnSubmit, 'click', () => {
    swal({
        title: "Do you want to proceed with this?",
        text: `Please review the ${recordType} information before proceeding`,
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#27c24c",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        closeOnCancel: true
    },
    function (isConfirm) {
        if (isConfirm) {
            proceed();
        }
    });
});

listen(btnCancel, 'click', () => {
    swal({
        title: `Do you want to cancel this ${recordType} process?`,
        text: "",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dd6b55",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        closeOnCancel: true
    },
    function (isConfirm) {
        if (isConfirm) {
            window.location.href = `/admin/${action}/organisation/${orgId}/session_entity/new`;
        }
    });
});
