import {createDateRangePickers, formatVaadinDates} from "../../common/date";
import {documentReady} from "../../document-ready";

documentReady().then(() => {
    // const specificPickers = document.querySelectorAll('vaadin-date-picker');
    const startDate = document.getElementById('start_date');
    const endDate = document.getElementById('end_date');

    formatVaadinDates([startDate,endDate], "YYYY-MM-DD");
    createDateRangePickers(startDate, endDate);
});