const filterButton = document.getElementById('filter_display') as HTMLInputElement;
const filterDiv = document.querySelector('#filters') as HTMLInputElement;

//show/hide transaction filters
filterButton.addEventListener('click', (e) => {
    if (filterDiv.style.display === "none") {
        filterButton.innerHTML = "Hide Filters";
        filterDiv.style.display = "block";
    } else {
        filterButton.innerHTML = "Show Filters";
        filterDiv.style.display = "none";
    }
});
