declare var moment: any;

export const getVaadinDatePickers: () => any = () => {
    return document.querySelectorAll('vaadin-date-picker');
};

export function setupVaadinDatePickers() {
    const dateInputs = getVaadinDatePickers();

    formatVaadinDates(dateInputs, 'YYYY-MM-DD');

    const datePickers = getVaadinDatePickers();
    createHiddenInputsForDatePickers(datePickers);
}

export function formatVaadinDates(dateInputs, format: string) {
    dateInputs.forEach(dateInput => {
        dateInput.i18n.parseDate = str => {
            const parsed = moment(str, format);

            return { year: parsed.year(), month: parsed.month(), day: parsed.date() };
        };

        dateInput.i18n.formatDate = date => moment(date).format(format);

        // Trigger change on the value to make sure our custom date format is applied
        const initialValue = dateInput.value;
        dateInput.value = '';
        dateInput.value = initialValue;
    });
}

/**
 * Sets up listeners to link date pickers together
 * so that users can select a date range
 */
export function createDateRangePickers(fromInput, toInput) {
    fromInput.addEventListener('change', () => {
        toInput.min = fromInput.value;
    });

    toInput.addEventListener('change', () => {
        fromInput.max = toInput.value;
    });

    // For initial values
    toInput.min = fromInput.value;
    fromInput.max = toInput.value;
}

export function createHiddenInputsForDatePickers(datePickers) {
    datePickers.forEach(datePicker => {
        // Check if it has a [name] attribute
        const inputName = datePicker.getAttribute('name');
        datePicker.removeAttribute('name');

        if (!inputName) {
            console.warn("No name specified for datepicker: ", datePicker);

            return;
        }

        const hiddenInput = document.createElement('input');
        hiddenInput.type = 'hidden';
        hiddenInput.name = inputName;
        hiddenInput.value = datePicker.value;

        // Listen for changes on the date picker and update hidden value
        datePicker.addEventListener('change', e => {
            hiddenInput.value = datePicker.value;
        });

        datePicker.insertAdjacentElement('afterend', hiddenInput);
    });
}