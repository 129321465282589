//#18330 - for entity tag import
import * as Papa from 'papaparse';

const fileInput = document.querySelector('#import_file') as HTMLInputElement;
//div where we show data from selected file
const div = document.querySelector("#data")!;

let entityType = document.querySelector('#entity_type') as HTMLInputElement;
let previewText = document.querySelector('#preview_text');
let columnCount = document.querySelector("#column_count");
let headerOption = document.querySelector("#header_option") as HTMLInputElement;
let header = document.querySelector('#header') as HTMLInputElement;
// @ts-ignore
let jsonInputElement = document.getElementById("tags") as HTMLInputElement;
const jsonString = jsonInputElement.value;
const tags = JSON.parse(jsonString);

//when 'File Contains Header' checkbox is checked/unchecked we hide/display header row in preview
headerOption.addEventListener('change', function(){
    let firstRow = document.querySelector('#firstRow') as HTMLInputElement;
    if (headerOption.checked) {
        firstRow.setAttribute('style', 'display:none');
    } else {
        firstRow.setAttribute('style', 'display:table-row');
    }
});

//on File type selection we show data preview
fileInput.addEventListener('change', showPreview);

function showPreview() {
    // @ts-ignore
    if (fileInput.files.length > 0) {
        headerOption.setAttribute('style', 'display:inline');
        header.setAttribute('style', 'display:block');
        // @ts-ignore
        Papa.parse(fileInput!.files[0], {
            preview: 11,
            complete: function (results) {
                if (results.data.length > 0) {
                    document.querySelector("#preview")!.setAttribute('style', 'display:block');
                    div.innerHTML = '';
                    const tbl = document.createElement('table');
                    tbl.setAttribute('class', 'table table-striped');
                    // @ts-ignore
                    generateTable(results.data, tbl);
                    div.append(tbl);
                } else {
                    document.querySelector("#preview")!.setAttribute('style', 'display:block');
                    let message = document.createTextNode("Data not found.");
                    div.innerHTML = '';
                    div.append(message);
                }
            }
        });
    } else {
        document.querySelector("#preview")!.setAttribute('style', 'display:none');
    }

    function generateTable(data, tbl) {
        let options = tags;

        let tbody = tbl.createTHead();
        let row = tbody.insertRow();
        let firstRow = data[0];
        let length = firstRow.length;
        // @ts-ignore
        columnCount.value = length;

        // @ts-ignore
        previewText.innerHTML = 'Data Type Selection';
        while (length > 0) {
            if (length !== firstRow.length) {
                //add dropdown in header for column type selection
                const select = document.createElement("select");
                select.classList.add("column");
                select.name = "column" + (firstRow.length - length);

                for (const key in options) {
                    let option = document.createElement("option");
                    option.value = key;
                    option.text = options[key];
                    select.appendChild(option);
                }
                let tdata = document.createElement('th');
                select.style.width = '140px';
                tdata.appendChild(select);
                row.appendChild(tdata);
            } else {
                let tdata = document.createElement('th');
                row.appendChild(tdata);
            }
            length--;
        }

        let i = 1;
        data.map((data) => {
            //if this is first row we created table head if needed
            if (i === 1) {
                // @ts-ignore
                previewText.innerHTML = 'Data Preview';
                //if header is not checked in bank_transaction entity type we create table body here
                let tbody = tbl.createTBody();
                let row = tbody.insertRow();
                row.setAttribute('id', 'firstRow');

                for (let key of data) {
                    let tdata = document.createElement('td');
                    let text = document.createTextNode(key);
                    tdata.appendChild(text);
                    row.appendChild(tdata);
                }
            } else {
                let tbody = tbl;
                if (entityType.value !== 'bank_transaction' && i === 2) {
                    //if file type is not bank_transaction and is second row we create table body and add data
                    tbody = tbl.createTBody();
                }
                let row = tbody.insertRow();

                for (let key of data) {
                    let tdata = document.createElement('td');
                    let text = document.createTextNode(key);
                    tdata.appendChild(text);
                    row.appendChild(tdata);
                }
            }
            i++;
        });
    }
}

