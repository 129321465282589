import { startSilentLogin } from "@cbar/cbar-oauth";
import {DEFAULT_POPUP_FEATURES, OAuthPopup} from "@cbar/oauth-popup";

const getCenterOfElement = element => {
    const rect = element.getBoundingClientRect();

    return {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
    };
};

const authUrl = `/auth/social?start=authentickated`;
const loadingSection = document.querySelector('#loading-indicator') as HTMLElement;

export const setupOAuthPopup = () => {
    if (isIframe()) {
        return;
    }

    if (OAuthPopup.isPopupWindow()) {
        // We're inside the OAuth popup.
        return handlePopupCallback();
    }

    const popup = new OAuthPopup;
    const loginButtons = document.querySelectorAll('.authentickated-login');

    const isLoginPage = loginButtons.length > 0;
    if (!isLoginPage) {
        return;
    }

    checkLoggedInStatus();

    const registerButton = document.querySelector('#register-button')!;

    const handler = function (this: HTMLButtonElement, event, register = false) {
        const e = event as MouseEvent;
        e.preventDefault();

        const buttonCenter = getCenterOfElement(this);

        // Open the popup around the clicked button
        let features = {
            ...DEFAULT_POPUP_FEATURES,
            top: Math.max(buttonCenter.y - DEFAULT_POPUP_FEATURES.height / 2),
            left: Math.max(buttonCenter.x - DEFAULT_POPUP_FEATURES.width / 2, 0),
        };

        let popupUrl = authUrl;
        if (register) {
            popupUrl += '&register=1';
        }

        if (popup.isPopupOpen()) {
            // Popup is already open, calling open now will just
            // focus the existing popup.
            return popup.open(popupUrl, features);
        }

        // Update the button text
        if(!!loadingSection) {
            loadingSection.innerHTML = 'Enter your login details in the popup...';
        }

        popup.open(popupUrl, features)
            .then(({redirectUrl}) => {
                if(!!loadingSection) {
                    loadingSection.innerHTML = 'Redirecting you now...';
                }

                window.location.href = redirectUrl;
            })
            .catch(e => {
                if(!!loadingSection) {
                    loadingSection.innerHTML = '';
                }
                console.error(e);
            });

        window.addEventListener('beforeunload', () => {
            // Close the popup if the window unloads.
            popup.close();
        });
    };

    loginButtons.forEach(button => {
        // Start a timer to see if the popup gets blocked
        button.addEventListener('click', handler.bind(button));
    });

    if(!!registerButton) {
        registerButton.addEventListener('click', e => {
            handler.call(registerButton, e, true);
        });
    }
};

export const handlePopupCallback = () => {
    const isLoginPage = window.location.pathname.indexOf('/auth/login') === 0;

    if (isLoginPage) {
        // We got redirected back to the login page in the popup,
        // let's re-start the login process.

        // Hide the page so that the login page does not flash in before we redirect.
        document.body.style.display = 'none';

        return window.location.href = authUrl;
    }

    // Retrieve redirect url from the hidden input in the /auth/social view
    const input = document.querySelector('#redirect_url') as HTMLInputElement;
    const redirectUrl = input ? input.value : '/account/index';

    // Attempt to notify if we're already in a popup/iframe.
    OAuthPopup.notifyOpener({redirectUrl});
};

export const checkLoggedInStatus = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const loggedoutParam = urlParams.get('loggedout');

    if (loggedoutParam) {
        showLogin();

        return;
    }

    startSilentLogin({ authUrl })
        .then(redirectUrl => {
            if (location.pathname === "/auth/login") {
                location.href = redirectUrl;

                return;
            }

            const accountLink = document.querySelector('.no_entity_account_link');
            if (accountLink) {
                accountLink.removeAttribute('hidden');
            }
        })
        .catch(e => {
            console.error("Silent sign in failed: ", e);
            showLogin();
        });
};

const isIframe = () => window.parent !== window;

const showLogin = () => {
    const loginButtons = document.querySelectorAll('.authentickated-login') as NodeListOf<HTMLElement>;
    const registerButton = document.querySelector('#register-button') as HTMLElement;
    const spinner = document.querySelector('paper-spinner') as any as {active: boolean};

    loginButtons.forEach(btn => {
        btn.hidden = false;
    });

    if (!!registerButton) {
        registerButton.hidden = false;
    }

    if(!!loadingSection){
        loadingSection.innerHTML = "";
    }

    if(!!spinner) {
        spinner.active = false;
    }

    loginButtons.forEach(button => {
        button.hidden = false;
    });
};
