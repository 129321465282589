//#17805 - for generic bank transaction file type import
import * as Papa from 'papaparse';

const fileInput = document.querySelector('#import_file') as HTMLInputElement;
const div = document.querySelector("#data")!;

let fileType = document.querySelector('#file_type') as HTMLInputElement;
let previewText = document.querySelector('#preview_text');
let columnCount = document.querySelector("#column_count");
let headerOption = document.querySelector("#header_option") as HTMLInputElement;
let header = document.querySelector('#header') as HTMLInputElement;

//#17805 - when 'File Contains Header' checkbox is checked/unchecked we hide/display header row in preview
headerOption.addEventListener('change', function(){
    let firstRow = document.querySelector('#firstRow') as HTMLInputElement;
    if (headerOption.checked) {
        firstRow.setAttribute('style', 'display:none');
    } else {
        firstRow.setAttribute('style', 'display:table-row');
    }
});

//#17805 - on File type selection we show data preview
fileInput.addEventListener('change', showPreview);

function showPreview() {
    // @ts-ignore
    if (fileInput.files.length > 0) {
        if (fileType.value == 'generic') {
            header.setAttribute('style', 'display:block');
        } else {
            headerOption.checked = false;
            header.setAttribute('style', 'display:none');
        }
        // @ts-ignore
        Papa.parse(fileInput!.files[0], {
            preview: 11,
            complete: function (results) {
                if (results.data.length > 0) {
                    document.querySelector("#preview")!.setAttribute('style', 'display:block');
                    div.innerHTML = '';
                    const tbl = document.createElement('table');
                    tbl.setAttribute('class', 'table table-striped');
                    // @ts-ignore
                    generateTable(results.data, tbl);
                    div.append(tbl);
                } else {
                    document.querySelector("#preview")!.setAttribute('style', 'display:block');
                    let message = document.createTextNode("Data not found.");
                    div.innerHTML = '';
                    div.append(message);
                }
            }
        });
    } else {
        document.querySelector("#preview")!.setAttribute('style', 'display:none');
    }

    function generateTable(data, tbl) {
        if (fileType.value == 'generic') {
            let options = {'none':'Set Column Data', 'date-d/m/Y': 'Date (DD/MM/YYYY)', 'date-m/d/Y':'Date (MM/DD/YYYY)', 'date-d-m-Y': 'Date (DD-MM-YYYY)', 'amount':'Amount', 'currency':'Currency', 'type':'Type (Dr/Cr)','amount-debit': "Debit Amount", 'amount-credit': 'Credit Amount', 'description' : 'Description', 'balance': 'Balance'};

            let tbody = tbl.createTHead();
            let row = tbody.insertRow();
            let firstRow = data[0];
            let length = firstRow.length;
            // @ts-ignore
            columnCount.value = length;

            // @ts-ignore
            previewText.innerHTML = 'Data Type Selection';
            while (length > 0) {
                //add dropdown in header for column type selection
                const select = document.createElement("select");
                select.classList.add("column");
                select.name = "column" + (firstRow.length - length);
                select.setAttribute('onchange', 'columnChange()');

                for (const key in options) {
                    let option = document.createElement("option");
                    option.value = key;
                    option.text = options[key];
                    select.appendChild(option);
                }
                let tdata = document.createElement('th');
                tdata.appendChild(select);
                row.appendChild(tdata);
                length--;
            }
        }

        let i = 1;
        data.map((data) => {
            //if this is first row we created table head if needed
            if (i === 1) {
                // @ts-ignore
                previewText.innerHTML = 'Data Preview';

                if (fileType.value !== 'generic') {
                    //if file type is not generic file type we create table head
                    let tbody = tbl.createTHead();
                    let row = tbody.insertRow();
                    for (let key of data) {
                        let tdata = document.createElement('th');
                        let text = document.createTextNode(key);
                        tdata.appendChild(text);
                        row.appendChild(tdata);
                    }
                } else {
                    //if header is not checked in generic file type we create table body here
                    let tbody = tbl.createTBody();
                    let row = tbody.insertRow();
                    row.setAttribute('id', 'firstRow');

                    for (let key of data) {
                        let tdata = document.createElement('td');
                        let text = document.createTextNode(key);
                        tdata.appendChild(text);
                        row.appendChild(tdata);
                    }
                }
            } else {
                let tbody = tbl;
                if (fileType.value !== 'generic' && i === 2) {
                    //if file type is not generic and is second row we create table body and add data
                    tbody = tbl.createTBody();
                }
                let row = tbody.insertRow();

                for (let key of data) {
                    let tdata = document.createElement('td');
                    let text = document.createTextNode(key);
                    tdata.appendChild(text);
                    row.appendChild(tdata);
                }
            }
            i++;
        });
    }
}

