export function makeRequest(url, params = {}, method = 'GET') {
  const encode = encodeURIComponent;
  let options = {method, headers: {}, body: ''};

  if (method === 'GET') {
    // Create a querystring
    url += '?' + Object.keys(params)
      .map(key => `${encode(key)}=${encode(params[key])}`)
      .join('&');
  } else {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(params);
  }

  return new Promise((res, rej) => {
    fetch(url, options).then(async (response) => {
      const json = await response.json();

      if (!response.ok) {
        return rej(json);
      }

      res(json);
    });
  });
}