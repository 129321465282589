import {documentReady} from "../../document-ready";
import {makeRequest} from "../../make-request";
import {PaperButtonElement} from "@polymer/paper-button";
import {PaperSpinnerElement} from "@polymer/paper-spinner/paper-spinner";


/**
 * Type that allows us to set the properties that are available to the element
 */
// @ts-ignore
interface CardElement extends HTMLElement {
  title?: string,
  details?: string,
  extraDetails?: string,
  owner?: string
}

interface RecurringCard extends HTMLElement{
  status: string
}

/**
 * details we expect to see from a card entry
 */
type CardDetails = {
  name?: string,
  owner?: string,
  number?: string | number,
  expiry?: string,
  cvv?: string,
}

/**
 * details we expect to see from a debit entry
 */
type DebitDetails = {
  accountName?: string,
  institution?: string,
  owner?: string,
  bsb?: string,
  accountNo?: string | number
}

/**
 * the dialog had a bunch of functionality that come up as errors, we have them added here
 */
interface CardDialog extends HTMLElement {
  loading: boolean,

  toggle(): void,
  reset(): void,

  paymentData: CardDetails & DebitDetails,
  addContext: "debit" | "card",
  context: "debit" | "card",
  updateWording: "Add" | "Update",
  modalAction: "Add" | "Update",
  errorMessage: string
}

/**
 * event that is given when the update button is fired
 */
interface UpdateEvent extends Event{
  detail?: {
      value: CardDetails & DebitDetails
  }
}

documentReady().then(() => {
  // Element grabbers here
  let addCardButton = document.querySelectorAll('.add-credit-card');
  let addDebitCardButton = document.querySelectorAll('.add-debit-card');
  let cardDialog = document.querySelector('cbar-paymentmethod-modal') as CardDialog;
  let confirmationDialog = document.querySelector('#confirmation-dialog') as CardDialog;
  let confirmationDialogMessage = document.querySelector('#confirmation-dialog .confirmation-message');
  let payorName = document.querySelector('.payor-name');

  // all of our repeated card elements
  let creditCards = document.querySelectorAll('.credit-card');
  let debitCards = document.querySelectorAll('.debit-account');
  let recurringCards = document.querySelectorAll('.recurring-card');

  // hidden payor id, helps us submit some information
  let payorId = document.querySelector('#payor-id') as HTMLInputElement;

  // custom delete dialog options here
  let deleteConfirmation = document.querySelector('#delete-confirmation') as CardDialog;
  let deleteConfirmationCancel = document.querySelector('paper-button.delete-cancel') as PaperButtonElement;
  let deleteConfirmationButton = document.querySelector('paper-button.delete-confirm') as PaperButtonElement;
  let deleteConfirmationSpinner = document.querySelector('paper-spinner.delete-confirm-spinner') as PaperSpinnerElement;

  let dialogContext: "card" | "debit" | "recurring";
  let id: string | null | undefined = null;

  /**
   * Whenever any of the debit/credit card divs fire their update functions
   * we need them to be able to format and send the data to the available update PHP action
   * @param element
   * @param context
   */
  function addCardUpdateHandler(element, context){
    element.addEventListener('update-clicked', (e) => {
      const element: CardElement | null = e.target as CardElement;

      dialogContext = context;
      id = element.dataset.id;

      let holder = element.dataset.holder

      cardDialog.addContext = cardDialog.context = context;
      cardDialog.modalAction = cardDialog.updateWording = "Update";

      // different data for different contexts
      cardDialog.paymentData = context === "card" ? {
        owner: holder ? holder : payorName?.innerHTML,
        number: element.details,
        expiry: element.extraDetails
      } : {
        accountName: element.title ? element.title : payorName?.innerHTML,
        bsb: element.extraDetails,
        accountNo: element.details
      }

      cardDialog.toggle();
    });
  }

  /**
   * When any of our cards fire a delete clicked action we want to ensure the user wishes
   * to actually fire this, open a confirm dialog and set our context, swap out message for appropriate
   * context
   * @param element
   * @param context
   */
  function addCardDeleteHandler(element, context){
    element.addEventListener('delete-clicked', (e) => {
      dialogContext = context;
      id = element.dataset.id;

      let confirmationMessage = deleteConfirmation.querySelector('.confirmation-message') as HTMLElement;
      let messageMap = {
        card: "card",
        debit: "debit account",
        recurring: "recurring payment"
      }

      confirmationMessage.innerHTML = `This will remove your selected ${messageMap[context]}. Please confirm below.`

      deleteConfirmation.toggle();
    });
  }

  /**
   * Add a new card to the target repeat div
   * Adds all required information as well as listeners that the card may require
   *
   * @param context
   * @param section
   * @param results
   */
  function addNewCard(context, section: HTMLElement, results){
    let divToCreate = document.createElement('cbar-paymentmethod-card') as CardElement;

    divToCreate.classList.add(context === "card" ? "credit-card" : "debit-account");
    divToCreate.details = context === "card" ? results.number : results.accountNumber;
    divToCreate.extraDetails = context === "card" ? results.expiry : results.bsb;
    // dont show title for cards
    divToCreate.title = context === "card" ? null : results.accountName;

    // we dont show owner for debits
    if(context === "card"){
      divToCreate.owner = results.owner;
    }

    addCardDeleteHandler(divToCreate, context);
    addCardUpdateHandler(divToCreate, context);

    divToCreate.dataset.id = results.id;

    section.appendChild(divToCreate);
  }

  /**
   * When we click the add button we need to set the context of the dialog
   * and then open in the corresponding form
   * @param element
   * @param context
   */
  function createNewCardHandler(element, context){
    element.addEventListener('click', (e) => {
      id = null;
      dialogContext = context;
      cardDialog.context = context;
      cardDialog.modalAction = cardDialog.updateWording = "Add";
      cardDialog.toggle();

      cardDialog.paymentData = context === "card" ? {
        owner:  payorName?.innerHTML,
      } : {
        accountName: payorName?.innerHTML,
      };
    })
  }

  /**
   * When we add / delete a card from a section, we wish to update the number of entries that displays
   * In the case of recurring, due to a display showing if we have none, then we need to display such message
   */
  function updateSectionCount(){
    let targetClassMap = {
      'card' : 'credit-card',
      'debit' : 'debit-account',
      'recurring': 'recurring-card'
    }

    let count = document.querySelectorAll(`.${targetClassMap[dialogContext]}`).length

    // recurring needs to set the inner HTML update
    if(dialogContext === "recurring" && count === 0){
      let wrapper = document.querySelector('.recurring-wrapper');
      if(wrapper){
        wrapper.innerHTML = "<p>You have no recurring payments set up.</p>"
      }
    }
  }

  /**
   * add our handlers for our buttons
   */
  addCardButton.forEach((element) => {
    createNewCardHandler(element, 'card')
  });

  addDebitCardButton.forEach((element) => {
    createNewCardHandler(element, 'debit');
  });

  /**
   * Main function, allows us to create new cards/debits
   * we need to format data here as we expect it, request and then respond correctly here
   */
  cardDialog.addEventListener('update-clicked', async (e : UpdateEvent ) => {
    let eventData = e?.detail?.value;
    let eventTarget : CardDialog = e.target as CardDialog;
    let cardSection = document.querySelector(`.${dialogContext}-section`) as HTMLElement;

    if(!eventData){
      eventTarget.errorMessage = "Invalid Data Given";
      eventTarget.loading = false;
      return;
    }

    let dataToSubmit = {};
    let idToSubmit = id ? id : ""
    cardDialog.loading = true;

    if(dialogContext === "card"){
      // @ts-ignore
      let expiryArray = eventData.expiry.split("/");
      let expiryMonth = expiryArray[0];

      //TODO: there might be a better way to handle this
      let expiryYear = `20${expiryArray[1]}`;

      dataToSubmit = {
          'cc_number' : eventData.number,
          'cc_month' : expiryMonth,
          'cc_year' : expiryYear,
          'ccv' : eventData.cvv,
          'cc_name' : eventData.owner,
      }
    }

    //TODO: ADD THIS LOGIC WHEN DEBIT WORKS
    if(dialogContext === "debit"){
      dataToSubmit = {
        'account_name' : eventData.accountName,
        'account_number' : eventData.accountNo,
        'bsb' : eventData.bsb
      }
    }

    if(Object.keys(dataToSubmit).length === 0){
      eventTarget.errorMessage = "Invalid Data Given";
      eventTarget.loading = false;
      return;
    }

    let results : any = await makeRequest(`/account/add${dialogContext}/payor/${payorId.value}${idToSubmit ? `/${dialogContext}/${idToSubmit}` : ""}`, dataToSubmit, 'POST');

    if(results.operation !== "success"){
      cardDialog.errorMessage = results.message;
      cardDialog.loading = false;
      return;
    }

    // show our confirmation here
    if(confirmationDialog && confirmationDialogMessage && cardSection){
      addNewCard(dialogContext, cardSection, results[dialogContext]);

      // if we need to remove a card, we do such here
      if(results[`${dialogContext}_removed`]){
        let cardToHide = cardSection.querySelector(`cbar-paymentmethod-card[data-id="${results[`${dialogContext}_removed`]}"]`);

        if(cardToHide){
          cardToHide.remove();
        }
      }

      // confirmation message
      confirmationDialogMessage.innerHTML = results.message;

      cardDialog.toggle();
      confirmationDialog.toggle();
      cardDialog.loading = false;

      updateSectionCount();
    }
  });

  // add our card listeners here
  Array.from(creditCards).forEach(element => {
    addCardUpdateHandler(element, 'card');
    addCardDeleteHandler(element, 'card');
  });

  Array.from(debitCards).forEach(element => {
    addCardUpdateHandler(element, 'debit');
    addCardDeleteHandler(element, 'debit');
  });

  Array.from(recurringCards).forEach(element => {
    addCardDeleteHandler(element, 'recurring')
  });

  /**
   * Delete action, fires when the "are you sure" dialog is confirmed
   */
  deleteConfirmationButton.addEventListener('click', async (e) => {
    let errorMessageDiv = deleteConfirmation.querySelector('.buttons .confirmation-error-message') as HTMLElement;
    let cardSection = document.querySelector(`.${dialogContext}-section`) as HTMLElement;
    let cardToHide = cardSection.querySelector(`[data-id="${id}"]`) as RecurringCard;

    // loading state
    deleteConfirmationButton.disabled = deleteConfirmationCancel.disabled = true;
    deleteConfirmationSpinner.active = true;
    errorMessageDiv.innerHTML = "";

    let idToSubmit = id ? id : ""

    // function checking, each of our funtions have a bunch of different params that need to be sent
    let action = dialogContext === "recurring" ? `edit${dialogContext}` : `delete${dialogContext}`;
    let target = dialogContext === "recurring" ? "payment" : dialogContext;
    let extraCheck = dialogContext === "recurring" ? "go/disable" : "";

    // recurring requires these 2 data pieces to be added to the request
    let args = dialogContext !== "recurring" ? {} : {
      id: idToSubmit,
      payor_id: payorId.value,
      active: cardToHide.status === "Active" ? 1 : 5
    }

    let results: any  = await makeRequest(`/account/${action}/payor/${payorId.value}${idToSubmit ? `/${target}/${idToSubmit}` : ""}${extraCheck ? `/${extraCheck}` : ``}`, args, "POST");

    // error handling here
    if(results.operation !== "success"){
      errorMessageDiv.innerHTML = results.message;
    } else {
      // display our resulting message in the confirmation dialog
      if(confirmationDialogMessage) {
        confirmationDialogMessage.innerHTML = results.message;
        deleteConfirmation.toggle();
        confirmationDialog.toggle();

        if(cardToHide){
          cardToHide.remove();
          // update the section count here
          updateSectionCount();
        }
      }
    }

    // reset the loading indicators
    deleteConfirmationButton.removeAttribute('disabled');
    deleteConfirmationCancel.removeAttribute('disabled');

    deleteConfirmationSpinner.removeAttribute('active');
  });
});