import {documentReady} from "./document-ready";
import {setupLogoutHandler} from "./handle-logout";
import {setupOAuthPopup} from "./oauth-popup";
import {action, controller, importRouteJs} from "./routes";
import {handleHeaderSidenav, setupOrganisationDropdownListeners} from "./header";
import {setupVaadinDatePickers} from "./common/date";
import {silentLoginCallback} from "@cbar/cbar-oauth";

// Import the js for this controller/action, if it exists.
importRouteJs(controller, action)
    .catch(() => 'No file found, that\'s ok.');

documentReady().then(e => {
    // Handle silent sign in callback
    silentLoginCallback();

    // Set up our OAuthPopup
    setupOAuthPopup();
    setupLogoutHandler();
    setupOrganisationDropdownListeners();

    handleHeaderSidenav();

    // Set default format (Y-m-d) for vaadin-date-picker and create
    // hidden inputs
    setupVaadinDatePickers();
});
